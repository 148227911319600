.aboutMenu {
    margin: 80px 0px;
}

.aboutMenu p {
    max-width: 400px;
    color: #666666;
    line-height: 30px;
    margin-top: 20px;
}


/* _____________________video____________________ */

.video {
    position: relative;
    margin: 80px 0px;
}

.VideoIframe {
    overflow: hidden;
    border: none;
    width: 100%;
}

.modal-header {
    margin-bottom: -28px;
    border: none;
}

.Playbtn {
    position: absolute;
    /* z-index: 10; */
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    padding: 18px 20px 18px 28px;
    cursor: pointer;
}

.Playbtn:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: var(--theme-main-orange);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.Playbtn:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: var(--theme-main-orange);
    border-radius: 50%;
    transition: all 200ms;
}

.Playbtn:hover:after {
    background-color: darken(var(--theme-main-orange), 10%);
}

.Playbtn i {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid #fff;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    50% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
        opacity: 0.5;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.6);
        opacity: 0;
    }
}

.aboutusMenuCounting {
    text-align: center;
}

.aboutusMenuCounting h2 {
    font-size: 50px;
    font-weight: 600;
    cursor: pointer;
    color: var(--theme-main-orange);
}

.aboutusMenuCounting h6 {
    font-size: 18px;
    color: #666666;
    font-weight: 400;
}

.video img {
    width: 100%;
    border-radius: 0px;
}

.aboutMenu .row {
    align-items: center;
}

.aboutMenu .slick-next {
    top: auto;
    bottom: 0;
    left: 75px;
    right: auto;
    margin: 0 auto;
    display: block;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: var(--border-radius);
    background-color: var(--theme-main-orange);
    opacity: unset;
    transition: 0.5s ease;
    border: 1px solid var(--theme-main-orange);
}

.aboutMenu .slick-prev {
    top: auto;
    bottom: 0;
    left: 20px;
    right: auto;
    display: block;
    margin: 0 auto;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: var(--border-radius);
    background-color: var(--theme-main-orange);
    opacity: unset;
    transition: 0.5s ease;
    border: 1px solid var(--theme-main-orange);
}

.aboutMenu .slick-next:hover,
.aboutMenu .slick-prev:hover {
    background-color: #ffffff;
}

.aboutMenu .slick-next::before {
    content: "\f105";
    font-family: FontAwesome;
    font-size: 25px;
    opacity: 1;
    transition: 0.5s ease;
}

.aboutMenu .slick-prev::before {
    content: "\f104";
    font-family: FontAwesome;
    font-size: 25px;
    opacity: 1;
    transition: 0.5s ease;
}

.aboutMenu .slick-arrow:hover::before {
    color: black;
}


/* ********************************************Responsive************************************************ */

@media (min-width: 1200px) and (max-width: 1410px) {
    .aboutusMenuCounting h2 {
        font-size: 46px;
    }
}


/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {
    .aboutusMenuCounting h2 {
        font-size: 40px;
    }
    .aboutMenu {
        margin: 60px 0px;
    }
    .aboutMenu p {
        line-height: 25px;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
    }
    .video {
        margin: 60px 0px;
    }
}


/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {
    .aboutMenu .slick-prev,
    .aboutMenu .slick-next {
        width: 35px;
        height: 35px;
    }
    .aboutMenu .slick-prev::before,
    .aboutMenu .slick-next::before {
        font-size: 20px;
    }
    .aboutMenu p {
        font-size: 15px;
        line-height: 24px;
        margin-top: 0px;
    }
    .aboutusMenuCounting h2 {
        font-size: 35px;
    }
    .aboutusMenuCounting h6 {
        font-size: 16px;
    }
    .Playbtn {
        padding: 13px 15px 13px 23px;
    }
}


/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {
    .aboutMenu .slick-prev,
    .aboutMenu .slick-next {
        width: 33px;
        height: 33px;
    }
    .aboutMenu .slick-prev::before,
    .aboutMenu .slick-next::before {
        font-size: 18px;
    }
    .aboutusMenuCounting h2 {
        font-size: 20px;
    }
    .aboutMenu p {
        margin-top: 10px;
        font-size: 14px;
        line-height: 22px;
    }
    .aboutMenu {
        margin: 40px 0px;
    }
    .video {
        margin: 40px 0px;
    }
}


/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {
    /* _____________________________ Slider _____________________________ */
    .aboutMenu .slick-prev,
    .aboutMenu .slick-next {
        width: 30px;
        height: 30px;
    }
    .aboutMenu .slick-next {
        left: 60px;
    }
    .aboutMenu .slick-prev::before,
    .aboutMenu .slick-next::before {
        font-size: 18px;
    }
    .aboutMenu {
        margin: 40px 0px;
    }
    .colForResponsive {
        margin-top: 20px;
    }
    .aboutMenu p {
        margin-top: 0px;
        font-size: 14px;
        line-height: 25px;
    }
    .aboutusMenuCounting h2 {
        font-size: 30px;
    }
    .aboutusMenuCounting h6 {
        font-size: 14px;
        margin-bottom: 25px;
    }
    .Playbtn {
        width: 20px;
        height: 18px;
    }
    .Playbtn:before {
        width: 35px;
        height: 35px;
    }
    .Playbtn i {
        border-left: 12px solid #fff;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
    }
    .Playbtn:after {
        width: 40px;
        height: 40px;
    }
    .video {
        position: relative;
        margin: 30px 0px;
    }
    .ourTeamMain {
        margin: 15px 0px 15px 0px;
    }
}
